import React from 'react'
import Layout from "../components/layout"
import insta from '../images/claudio-insta.png'
import SEO from '../components/seo'

export default function Article() {
    return (
        <Layout>
            <SEO title="Claudio's Lament" image={insta} />
            <h1>
            Claudio's Lament
            </h1>
            <img src={insta} />
            <p>Heartfelt apology or empty words? Does it matter after her death?</p>

            <p>“I'm deeply sorry for the hurt and embarrassment I've caused to those close to Hero and everyone this has affected. This series of events and my callous response caused me to lose the most important thing in my life, the person I love and respect the most, Hero. I love her, I love her, I'm so sorry.”</p>
        </Layout>
    )
}
